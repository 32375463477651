import React from "react";
import {BRAND_NAME} from "../../../../config/_variable";
import FundamarketsLoader from "./fundamarkets";
import ExcotradesLoader from "./excotrades";
import RidgewaymarketsLoader from "./ridgewaymarkets";
import AlliumLoader from "./alliumtrades";
import MinosLoader from "./minosmarkets";
import CronoscapLoader from "./cronoscap";
import MetaproLoader from "./metapromarkets";
import QuorvexLoader from "./quorvex";
import BlueOceanLoader from "./blueocean";
import AntosLoader from "./antos";

export default function Loader(props) {
    const {size} = props;
    let component;
    switch (BRAND_NAME) {
        case "fundamarkets":
            component = <FundamarketsLoader size={size}/>;
            break;
        case "excotrades":
            component = <ExcotradesLoader size={size}/>;
            break;
        case "metapromarkets":
            component = <MetaproLoader size={size}/>;
            break;
        case "ridgewaymarkets":
            component = <RidgewaymarketsLoader size={size}/>;
            break;
        case "alliumtrades":
            component = <AlliumLoader size={size}/>;
            break;
        case "minosmarkets":
            component = <MinosLoader size={size}/>;
            break;
        case "cronoscap":
            component = <CronoscapLoader size={size}/>;
            break;
        case "quorvex":
            component = <QuorvexLoader size={size}/>
            break
        case "blueocean":
            component = <BlueOceanLoader size={size}/>
            break
        case "antos":
            component = <AntosLoader size={size}/>
            break

        default:
            component = <ExcotradesLoader size={size}/>;
            break;
    }

    return component;
}
