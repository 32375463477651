import {ApolloClientQuery} from "./_setup";
import {gql} from "@apollo/client";

const GET_SYMBOL_PRICE_CT = gql`
    query getSpreadGroupSymbolPriceCT($param:JSON) {
        getSpreadGroupSymbolPriceCT(param:$param)
    }
`;

export async function getSymbolPrice(symbolId, spreadGroup, server) {
    let params = {
        spreadGroup: spreadGroup,
        serverName: server,
        symbolId: symbolId || null,
    };

    params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value != null));

    const variables = {
        param: JSON.parse(JSON.stringify(params)),
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(GET_SYMBOL_PRICE_CT, variables, false);
    return execute?.getSpreadGroupSymbolPriceCT;
}

export async function getSymbolPriceByName(name, groupId, serverName) {
    let params = {
        groupId,
        serverName,
    };

    if (name) {
        params.symbol = name;
    }

    params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value != null));

    const variables = {
        param: JSON.parse(JSON.stringify(params)),
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(GET_SYMBOL_PRICE_CT, variables, false);
    return execute?.getSpreadGroupSymbolPriceCT;
}

const GET_SYMBOL_PRICE_MT = gql`
    query getSpreadGroupSymbolPriceMT($param:JSON) {
        getSpreadGroupSymbolPriceMT(param:$param)
    }
`;

export async function getSymbolPriceMt5(url, symbolName, spreadGroup) {
    let params = {
        symbol: symbolName,
        spreadGroup: spreadGroup,
    };

    params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value != null));

    const variables = {
        param: JSON.parse(JSON.stringify(params)),
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(GET_SYMBOL_PRICE_MT, variables, false);
    return execute?.getSpreadGroupSymbolPriceMT;
}
