import React, {useState} from "react";
import "moment-timezone"
import {useTranslation} from "react-i18next";
import {formatDate, formatDateTime, useIsMobile} from "../../../../../_common/function";
import {Badge, Card, Col, List, Modal, Row, Tag, Typography} from "antd";
import VirtualList from "rc-virtual-list";
import EmptyData from "../../../../../../brand/component/emptyData/view";
import Loader from "../../../../../../brand/component/loader/view";
import {updateUserAnnouncementReadStatus} from "../../../../../api/graphql/announcement";
import {useSetRecoilState} from "recoil";
import {globalUnreadAnnouncementCountState} from "../../../../../_common/global_state";

const {Paragraph} = Typography

const AnnouncementListing_5 = (props) => {
    const {
        isHeader,
        data,
        loading,
        total
    } = props
    const {t} = useTranslation();
    const isMobile = useIsMobile(768);
    const [selected, setSelected] = useState({})
    const [modalOpen, setModalOpen] = useState(false);
    const [currentData, setCurrentData] = useState({})
    const [updatedRead, setUpdatedRead] = useState([])
    const setUnreadAnnouncementCount = useSetRecoilState(globalUnreadAnnouncementCountState);

    // const isSelectedEmpty = Object.keys(selected).length === 0;
    const ContainerHeight = 400;

    const renderTinyMCEContent = (content) => {
        return <div className={"tinyMceParagraph"} dangerouslySetInnerHTML={{__html: content}}/>;
    };

    const selectItem = (item) => {
        setSelected({})
    }

    // useEffect(() => {
    //     if (data?.length > 0) {
    //         if (!isMobile) {
    //             selectItem(data?.[0]);
    //         }
    //     } else {
    //         setSelected([]);
    //     }
    // }, [data])

    const loadingComponent = <>
        <Col span={24} className={"text-center mb-4"}>
            <div style={{minHeight: "400px"}}>
                <Loader/>
            </div>
        </Col>
    </>

    const dateToday = formatDate(new Date(), true, "YYYY-MM-DD")

    const listComponent =
        // isMobile && !isSelectedEmpty ? null :
        <List>
            <VirtualList
                className={"clickable"}
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemKey="stock"
            >
                {(item, number) => {
                    const title = item?.dataOfObject?.title
                    const description = item?.dataOfObject?.description
                    const time = item?.createdAt
                    const isToday = formatDate(time, true, "YYYY-MM-DD") === dateToday

                    return (
                        <>
                            <Card
                                className={"announcement-card"}
                                onClick={async () => {
                                    if (isHeader) {
                                        setCurrentData(item)
                                        setModalOpen(true);
                                        if (!item?.isRead && !updatedRead.includes(item?._id)) {
                                            const res = await updateUserAnnouncementReadStatus([item?._id], true)
                                            if (res?.updateUserAnnouncementReadStatus[0]?.isRead) {
                                                setUpdatedRead(prev => [...prev, res?.updateUserAnnouncementReadStatus[0]?._id])
                                                setUnreadAnnouncementCount(prev => prev - 1)
                                            }
                                        } else if (item?.isAnnouncementUpdated && !updatedRead.includes(item?._id)) {
                                            const res = await updateUserAnnouncementReadStatus([item?._id], true)
                                            setUpdatedRead(prev => [...prev, res?.updateUserAnnouncementReadStatus[0]?._id])
                                        }
                                    } else {
                                        selectItem(item)
                                    }
                                }}
                                key={number}
                            >
                                <Row align={"middle"} wrap={false}>
                                    <Col flex="auto">
                                        <Row wrap={false}>
                                            {!item?.isRead && !updatedRead.includes(item?._id) &&
                                            <Col xs={1} md={2}>
                                                <Badge dot offset={[0, 2]}/>
                                            </Col>
                                            }
                                            <Col xs={!item?.isRead && !updatedRead.includes(item?._id) ? 23 : 24}
                                                 md={!item?.isRead && !updatedRead.includes(item?._id) ? 22 : 24}>
                                                <Paragraph ellipsis className={"title"}>
                                                    {title}
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row wrap={false} className={"date-time"}>
                                            <span className={"secondary-text"}>
                                                {formatDateTime(time, isToday ? "hh:mm A" : "DD/MM, hh:mm A")}
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row wrap={false} align={"middle"}>
                                    <Col flex={"auto"}>
                                        <Paragraph ellipsis={{rows: 2}} className={"secondary-text description"}>
                                            {description}
                                        </Paragraph>
                                    </Col>
                                    {item?.isAnnouncementUpdated && !updatedRead.includes(item?._id) &&
                                    <Col>
                                        <Tag className={"announcement-updated-tag"} color="processing">
                                            Updated
                                        </Tag>
                                    </Col>
                                    }
                                </Row>
                            </Card>
                            {/*{(number === 7 || number === total - 1) &&*/}
                            {/*<div className={"p-2 text-center"}>*/}
                            {/*    <Button type={"primary"}>See All</Button>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </>
                    )
                }}
            </VirtualList>
        </List>

    const emptyComponent = (
        <div className={"mx-auto mt-4"}>
            <EmptyData/>
        </div>
    )

    return (
        <div id={"announcementListing"}>
            {loading ? loadingComponent :
                data?.length > 0 ? listComponent :
                    emptyComponent
            }
            <Modal
                title={
                    <>
                        {currentData?.dataOfObject?.title}
                        <p className={"date-time secondary-text"}>
                            {formatDateTime(currentData?.createdAt, "MMMM Do YYYY, h:mm:ss a")}
                            {currentData?.announcementUpdatedAt && ` (Last updated on ${formatDateTime(currentData?.announcementUpdatedAt, "MMMM Do YYYY, h:mm:ss a")})`}
                        </p>
                    </>
                }
                width={isMobile ? "90%" : "60%"}
                open={modalOpen}
                footer={null}
                onCancel={() => setModalOpen(false)}
                className={"announcement-modal"}
                getContainer={() => document.querySelector("#layout-5")}
            >
                <p>{currentData?.dataOfObject?.description}</p>
                <div className={"mt-3"}>{renderTinyMCEContent(currentData?.dataOfObject?.content)}</div>
            </Modal>
        </div>
    )
};

export default AnnouncementListing_5;