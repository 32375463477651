import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Upload,
} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {globalUserDetailState} from "../../../../_common/global_state";
import {PlusOutlined} from "@ant-design/icons";
// import moment from "moment";
import useLayoutFunction from "../../../function";
// import dayjs from "dayjs";
import {formatDate} from "../../../../_common/function";

const GoLiveModal = (props) => {
    const {isModalOpen, handleCancel, registerField} = props;
    const [form] = Form.useForm();

    const [btnLoading, setBtnLoading] = useState(false);
    const [countryCode, setCountryCode] = useState(null);

    const userDetails = useRecoilValue(globalUserDetailState);

    const {goLiveFunction} = useLayoutFunction();

    const sortedField = [...registerField]
        ?.filter(
            (v) =>
                v?.fieldList !== null &&
                v.fieldList.fieldName !== "checkTermAndCondition" &&
                v.fieldList.fieldName !== "affiliateCode" &&
                v.fieldList.category !== "loginInformation"
        )
        .sort((a, b) => a.position - b.position);

    const onFinish = async (values) => {
        await setBtnLoading(true);

        if (countryCode) {
            values.countryCode = countryCode;
        }
        Object.keys(values).map((value) => {
            const getField = sortedField.find(
                (item) => item?.fieldList?.fieldName === value
            );
            if (getField?.fieldList?.fieldType === "file") {
                values[value] = values[value].file.response[0].link.toString();
            } else if (getField?.fieldList?.fieldType === "date") {
                // values[value] = moment(values[value]).toISOString()
                values[value] = formatDate(values[value]).toISOString()
            }
        });

        values = {
            ...values,
            clientAccountType: "live",
        };
        await goLiveFunction(values);
        setBtnLoading(false);
        handleCancel();
    };

    const {t} = useTranslation();

    return (
        <Modal
            title={"Go Live"}
            className={"goLiveModal"}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            getContainer={() => document.getElementById("layout-2")}
            destroyOnClose
        >
            <div>
                <Form
                    className={"goLiveForm"}
                    name="basic"
                    form={form}
                    layout={"vertical"}
                    labelCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Row gutter={[16, 16]}>
                        {sortedField?.map((register) => {
                            let component;
                            let defaultValue

                            if (register?.fieldList?.fieldType === "date") {
                                // defaultValue = null
                                defaultValue = !userDetails?.[register?.fieldList?.fieldName]
                                    ? null
                                    // : dayjs(dayjs(userDetails?.[register?.fieldList?.fieldName])?.utc()?.format("YYYY-MM-DD") + "T12:00:00+00:00");
                                    : formatDate(userDetails?.[register?.fieldList?.fieldName])
                            } else {
                                defaultValue = register?.fieldList?.fieldName !== "affiliateCode" ? userDetails?.[register?.fieldList?.fieldName] : ""
                            }
                            switch (register?.fieldList?.fieldType) {
                                case "mobile":
                                    component = (
                                        <Space.Compact
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "end",
                                            }}
                                        >
                                            <Form.Item
                                                label={t(register?.fieldList?.label)}
                                                name="countryCode"
                                                initialValue={userDetails?.countryCode}
                                                style={{
                                                    marginTop: 0,
                                                    padding: 0,
                                                }}
                                                rules={[
                                                    {
                                                        required: register?.fieldList?.isRequired,
                                                        message:
                                                            "Please input your " +
                                                            t(register?.fieldList?.label),
                                                        // message: t("common.msgRules") + t(register?.fieldList?.label),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className={"mobileSelector"}
                                                    style={{
                                                        width: 80,
                                                    }}
                                                    showSearch
                                                    // defaultValue={userDetails?.countryCode}
                                                    onChange={(e) => setCountryCode(e)}
                                                    popupMatchSelectWidth={false}
                                                    optionFilterProp={"label"}
                                                    optionLabelProp={"value"}
                                                    options={register?.fieldList?.optionValue.map((d) => {
                                                        return {
                                                            label: t(d?.label),
                                                            value: d?.value,
                                                            key: d?.label,
                                                        };
                                                    })}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={""}
                                                className={"mobileInput"}
                                                name="mobileNo"
                                                style={{
                                                    width: "100%",
                                                    marginTop: 0,
                                                    padding: 0,
                                                    borderLeft: "1px solid var(--popover-bg-color)",
                                                }}
                                                initialValue={userDetails?.mobileNo}
                                            >
                                                <InputNumber
                                                    style={{width: "100%"}}
                                                    controls={false}
                                                />
                                            </Form.Item>
                                        </Space.Compact>
                                    );

                                    break;
                                case "text":
                                    component = (
                                        // <span className={"registerAccount"}>
                                        //   <Input autoComplete={"off"} defaultValue={defaultValue}/>
                                        <Input autoComplete={"off"}/>
                                        // </span>
                                    );
                                    break;
                                case "textarea":
                                    component = <Input.TextArea/>;
                                    break;
                                case "date":
                                    component = <DatePicker
                                        // disabledDate={(current) => {
                                        //     // return current && current.isAfter(dayjs().subtract(18, "years"));
                                        //     return current && current.isAfter(formatDate().subtract(18, "years"));
                                        // }}
                                        style={{width: "100%"}}
                                        className={"mt-0"}
                                        showToday={false}
                                    />;
                                    break;
                                case "file":
                                    component = (
                                        <Upload action="/upload.do" listType="picture-card">
                                            <div>
                                                <PlusOutlined/>
                                                <div style={{marginTop: 8}}>Upload</div>
                                            </div>
                                        </Upload>
                                    );
                                    break;
                                case "dropdown":
                                    component = (
                                        <Select showSearch>
                                            {register?.fieldList?.optionValue.map((value) => {
                                                return (
                                                    <Select.Option
                                                        key={value?.value}
                                                        value={value?.value}
                                                    >
                                                        {t(value?.label)}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    );
                                    break;
                                case "radioButton":
                                    component = (
                                        <Radio.Group>
                                            {register?.fieldList?.optionValue.map((value) => {
                                                return (
                                                    <Radio value={value?.value} key={value?.value}>
                                                        {t(value?.label)}
                                                    </Radio>
                                                );
                                            })}
                                        </Radio.Group>
                                    );
                                    break;
                                case "checkbox":
                                    component = <Checkbox/>;
                                    break;
                                case "checkboxGroup":
                                    component = (
                                        <Checkbox.Group
                                            options={register?.fieldList?.optionValue}
                                        />
                                    );
                                    break;
                                case "password":
                                    component = <Input.Password autoComplete={"off"}/>;
                                    break;
                            }

                            // This is required to prevent nested input control bug
                            if (register?.fieldList?.fieldType === "mobile") {
                                return (
                                    <Col md={12} xs={24} key={register?.fieldList?.fieldName}>
                                        {component}
                                    </Col>
                                );
                            }

                            const rules = [
                                {
                                    required: register?.fieldList?.isRequired,
                                    message: t("errorMsg.required.pleaseFillIn", {
                                        field: t(register?.fieldList?.label),
                                    }),
                                }
                            ]

                            if (register?.fieldList?.fieldName?.includes("email")) {
                                rules.push({
                                    type: "email",
                                    message: t("errorMsg.type.email"),
                                })
                            }

                            if (register?.fieldList?.fieldName?.includes("dateOfBirth")) {
                                rules.push({
                                    validator: (_, value) => {
                                        if (value && value.isAfter(formatDate().subtract(18, "years"))) {
                                            return Promise.reject(new Error(t("admin.err.user_must_at_least_18_year_old")));
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            }

                            return (
                                <Col md={12} xs={24} key={register?.fieldList?.fieldName}>
                                    <Form.Item
                                        label={t(register?.fieldList?.label)}
                                        name={register?.fieldList?.fieldName}
                                        rules={rules}
                                        initialValue={defaultValue}
                                    >
                                        {component}
                                    </Form.Item>
                                </Col>
                            );
                        })}
                    </Row>
                    <Col md={24} xs={24} className={"registerBtn text-end mt-4"}>
                        <Space>
                            <Button
                                className={"secondary_btn"}
                                onClick={() => handleCancel()}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button
                                htmlType={"submit"}
                                loading={btnLoading}
                                disabled={btnLoading}
                            >
                                {t("common.submit")}
                            </Button>
                        </Space>
                    </Col>
                </Form>
            </div>
        </Modal>
    );
};

export default GoLiveModal;
