import React, {useEffect, useState} from "react";
import {Badge, Button, Col, Layout, Menu, Popover, Row, Space, Tooltip,} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    globalBrandSettingState,
    globalColorThemeState,
    globalLanguageListState, globalUnreadAnnouncementCountState,
    globalUserDetailState,
} from "../../../_common/global_state";
import QrModal from "./qr/qrModal";
import PlatformDownloadModal from "./platformDownload/platformDownloadModal";
import {getPlatform} from "../../../api/graphql/platformDownload";
import {useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import GoLiveModal from "./goLive";
import {useVerification} from "../../../_common/_authorize";
import useLayoutFunction from "../../function";
import Announcement_3 from "../../../pages/announcement/view/layout_3";
import {getUserAnnouncementUnreadCount} from "../../../api/graphql/announcement";

export const Header_3 = (props) => {
    const {
        registerField,
        setCollapsed,
        children,
        openPlatformModal,
        setOpenPlatformModal,
        openQrModal,
        setOpenQrModal,
        openGoLiveModal,
        setOpenGoLiveModal
    } = props;
    const {t} = useTranslation();
    const {handleTokenExpiration} = useVerification();
    const {Header} = Layout;
    const [platformData, setPlatformData] = useState([]);
    const userDetails = useRecoilValue(globalUserDetailState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const [unreadAnnouncementCount, setUnreadAnnouncementCount] = useRecoilState(globalUnreadAnnouncementCountState);
    const navigate = useNavigate();
    const {IconCommon, IconFlag} = useShared();
    const {changeLanguageHandler} = useLayoutFunction();

    const userId = localStorage.getItem("userid")

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const unreadCount = await getUserAnnouncementUnreadCount(userId);
        setUnreadAnnouncementCount(unreadCount?.getUserAnnouncementByUserId?.unreadTotal);
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();
    }, []);

    const languageDropdown = (
        <Menu
            className={"headerLanguage"}
            selectedKeys={[localStorage.getItem("language")]}
        >
            {(languageListState ?? [])?.map((lang) => (
                <Menu.Item
                    key={lang?.code}
                    value={lang?.code}
                    onClick={() => changeLanguageHandler(lang?.code)}
                >
                    <Space><IconFlag countryCode={lang?.countryCode} style={{width: "24px"}}/> {lang?.nativeName}
                    </Space>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Header id={"layout-header"}>
            <Row justify={"space-between"} wrap={false}>
                <Col className="header-icon-section">
                    <img
                        alt={""}
                        className={"clickable"}
                        onClick={() => navigate("/dashboard")}
                        src={
                            colorState === "dark"
                                ? brandSettingState?.companyLogoInverse
                                : brandSettingState?.companyLogo
                        }
                    />
                    <div className="header-breadcrumb d-none d-lg-block">{children}</div>
                </Col>
                <div className="header-menu-section">
                    {userDetails?.clientAccountType === "demo" && (
                        <Button
                            type="primary"
                            className="goLiveButton hide-sm me-3"
                            onClick={() => {
                                setOpenGoLiveModal(true);
                            }}
                        >
                            {t("common.goLive")}
                        </Button>
                    )}

                    <div className="header-profile-section me-2">
                        {userDetails.username}
                    </div>
                    <Badge count={unreadAnnouncementCount} size={"small"} className="hide-sm">
                        <Popover
                            className="hide-sm"
                            trigger={"click"}
                            getPopupContainer={() => document.querySelector("#layout-3")}
                            content={<Announcement_3 isHeader/>}
                        >
                            <Tooltip
                                title={"Announcement"}
                                getPopupContainer={() => document.querySelector("#layout-3")}
                            >
                                <Button type="default" className="square icon">
                                    <IconCommon iconName={"cm-notification-regular"}/>
                                </Button>
                            </Tooltip>
                        </Popover>
                    </Badge>
                    <Tooltip
                        title={t("referral.modal.title")}
                        getPopupContainer={() => document.querySelector("#layout-3")}
                    >
                        <Button
                            type="default"
                            className="square icon hide-sm"
                            onClick={() => {
                                setOpenQrModal(true);
                            }}
                        >
                            <IconCommon iconName={"cm-qr-regular"}/>
                        </Button>
                    </Tooltip>
                    <Popover
                        className="hide-sm"
                        trigger={"click"}
                        getPopupContainer={() => document.querySelector("#layout-3")}
                        content={languageDropdown}
                    >
                        <Tooltip title={t("common.language")}
                                 getPopupContainer={() => document.querySelector("#layout-3")}>
                            <Button type="default" className="square icon">
                                <IconCommon iconName={"cm-language-regular"}/>
                            </Button>
                        </Tooltip>
                    </Popover>
                    <Tooltip title={t("common.theme")} getPopupContainer={() => document.querySelector("#layout-3")}>
                        <Button type="default" className="square icon hide-sm" onClick={() => {
                            if (colorState === "light") {
                                setColorState("dark");
                                localStorage.setItem("color", "dark");
                            } else {
                                setColorState("light");
                                localStorage.setItem("color", "light");
                            }
                        }}>
                            <IconCommon iconName={`cm-${colorState}theme-regular`}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={t("module.platformDownload")}
                             getPopupContainer={() => document.querySelector("#layout-3")}>
                        <Button
                            type="default"
                            className="square icon hide-sm"
                            onClick={() => {
                                setOpenPlatformModal(true);
                            }}
                        >
                            <IconCommon iconName={"cm-download-regular"}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={t("common.logout")} getPopupContainer={() => document.querySelector("#layout-3")}>
                        <Button
                            type="default"
                            className="square icon hide-sm"
                            onClick={() => handleTokenExpiration()}
                        >
                            <IconCommon iconName={"cm-logout-regular"}/>
                        </Button>
                    </Tooltip>
                    <Badge count={unreadAnnouncementCount} size={"small"} className="show-sm">
                        <Button
                            type="default"
                            className="square icon show-sm"
                            onClick={() => {
                                setCollapsed((c) => !c);
                            }}
                        >
                            <IconCommon iconName={"cm-hamburger-regular"}/>
                        </Button>
                    </Badge>
                </div>
            </Row>

            <QrModal
                isModalOpen={openQrModal}
                handleCancel={() => setOpenQrModal(false)}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <PlatformDownloadModal
                isModalOpen={openPlatformModal}
                handleCancel={() => setOpenPlatformModal(false)}
                platformData={platformData}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <GoLiveModal
                isModalOpen={openGoLiveModal}
                handleCancel={() => setOpenGoLiveModal(false)}
                registerField={registerField}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
        </Header>
    );
};
