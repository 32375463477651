import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import AnnouncementListing_4 from "./component/announcementListing";
import {getUserAnnouncementByUserId} from "../../../../api/graphql/announcement";
import {globalNotificationState, globalUnreadAnnouncementCountState} from "../../../../_common/global_state";
import {useSetRecoilState} from "recoil";
import "./css/style.css"

const Announcement_4 = (props) => {
    const {isHeader} = props
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const setUnreadAnnouncementCount = useSetRecoilState(globalUnreadAnnouncementCountState);
    const [total, setTotal] = useState(0)
    const [data, setData] = useState({});

    const userId = localStorage.getItem("userid")

    const preload = async () => {
        try {
            const response = await getUserAnnouncementByUserId(userId, 8, 0);
            setData(response?.getUserAnnouncementByUserId?.data);
            setUnreadAnnouncementCount(response?.getUserAnnouncementByUserId?.unreadTotal);
            setTotal(response?.getUserAnnouncementByUserId?.total);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the platform download page. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    }

    useEffect(() => {
        preload()
    }, [])

    const items = [
        {
            key: "1",
            label: "Announcement",
            children: <AnnouncementListing_4 isHeader={isHeader} data={data} total={total}/>,
        },
        // {
        //     key: "2",
        //     label: "Notification",
        //     children: "Content of Tab Pane 2",
        // },
    ];

    return (
        <Tabs
            id={"announcement"}
            defaultActiveKey="1"
            items={items}
        />
    )
}

export default Announcement_4