import React, {useEffect, useState} from "react";
import {Avatar, Badge, Button, Col, Divider, Drawer, Layout, Popover, Row, Space} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    globalBrandSettingState,
    globalColorThemeState,
    globalLanguageListState,
    globalOpenGoLiveModalState,
    globalUnreadAnnouncementCountState,
    globalUserDetailState,
} from "../../../_common/global_state";
import {DownOutlined} from "@ant-design/icons";
import QrModal from "./qr/qrModal";
import {useIsMobile} from "../../../_common/function";
import PlatformDownloadModal from "./platformDownload/platformDownloadModal";
import {getPlatform} from "../../../api/graphql/platformDownload";
import dummyImg from "../../../assets/image/dummy.png";
import {useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import GoLiveModal from "./goLive";
import {useVerification} from "../../../_common/_authorize";
import useLayoutFunction from "../../function";
import FooterTime from "./latestTime/footerTime";
import {getUserAnnouncementUnreadCount} from "../../../api/graphql/announcement";
import Announcement_1 from "../../../pages/announcement/view/layout_1";

export const Header_1 = (props) => {
    const {registerField, headerRoutes, mobileRoutes} = props;
    const {t} = useTranslation();
    const isMobile = useIsMobile(768);
    const {handleTokenExpiration} = useVerification();
    const {Header} = Layout;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [openQrModal, setOpenQrModal] = useState(false);
    const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [platformData, setPlatformData] = useState([]);
    const userDetails = useRecoilValue(globalUserDetailState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {IconCommon, IconFlag, IconModule} = useShared();
    const {changeLanguageHandler} = useLayoutFunction();
    const [popoverContentConfigKey, setPopoverContentConfig] = useState();
    const [unreadAnnouncementCount, setUnreadAnnouncementCount] = useRecoilState(globalUnreadAnnouncementCountState);

    const userId = localStorage.getItem("userid")

    const onClose = () => {
        setOpen(false);
    };

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const res = await getUserAnnouncementUnreadCount(userId);
        setUnreadAnnouncementCount(res?.getUserAnnouncementByUserId?.unreadTotal)
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();
    }, []);

    const popoverContent = (
        <div>
            <div className="avatar-section">
                <Avatar className="profile-avatar" size={48} src={userDetails?.profileImage || dummyImg}/>
                <div>
                    <div className="username">{userDetails?.username}</div>
                    <div className="accountTypeBadge">{t(`user.statusTag.${userDetails?.clientAccountType}`)}</div>
                </div>
            </div>
            {userDetails?.clientAccountType === "demo" ? (
                <Button
                    type="primary"
                    className="goLiveButton"
                    onClick={() => setOpenGoLiveModal(true)}
                >
                    {t("common.goLive")}
                </Button>
            ) : null}
            <div className="popover-select-item-list mt-2">
                <Button
                    type="text"
                    onClick={() => {
                        setOpenQrModal(true);
                        setPopoverOpen(false);
                    }}
                >
                    {t("referral.modal.title")}
                </Button>
                <Button
                    type="text"
                    onClick={() => navigate("/profile")}
                >
                    {t("common.editProfile")}
                </Button>
                <Button
                    type={"text"}
                    onClick={() => handleTokenExpiration()}
                >
                    {t("common.logOut")}
                </Button>
            </div>
        </div>
    );

    const platformDowloadKey = platformData ? Object.entries(platformData) : [];

    const getNestedPopoverContent = (key) => {
        if (platformDowloadKey.some((k) => k[0] === key)) {
            const platforms = Object.entries(platformData[key]).reduce(
                (a, b) => (b?.[1]?.__typename === "LinkObject" && b?.[1]?.isEnabled && b?.[1]?.link ? [...a, b] : a),
                []
            );
            return (
                <>
                    {platforms.map((p, i) => (
                        <Button
                            key={i}
                            type="text"
                            onClick={() => window.open(p?.[1]?.link, "_blank")}
                        >
                            {p?.[1]?.displayName}
                        </Button>
                    ))}
                </>
            );
        }

        switch (key) {
            case "language":
                return (
                    <>
                        {(languageListState ?? [])?.map((lang) => (
                            <Button
                                key={lang?.nativeName}
                                type={"text"}
                                onClick={() => changeLanguageHandler(lang?.code)}
                            >
                                <IconFlag countryCode={lang?.countryCode} style={{width: "24px", marginRight: "8px"}}/>
                                <div>{lang?.nativeName}</div>
                            </Button>
                        ))}
                    </>
                );
            case "theme":
                return (
                    <>
                        <Button
                            type="text"
                            onClick={() => {
                                setColorState("light");
                                localStorage.setItem("color", "light");
                            }}
                        >
                            {t("common.light")}
                        </Button>
                        <Button
                            type="text"
                            onClick={() => {
                                setColorState("dark");
                                localStorage.setItem("color", "dark");
                            }}
                        >
                            {t("common.dark")}
                        </Button>
                    </>
                );

            case "download":
                return (
                    <>
                        {platformDowloadKey.map(([k], i) => (
                            <Button
                                key={i}
                                type="text"
                                onClick={() => setPopoverContentConfig(k)}
                            >
                                {k}
                            </Button>
                        ))}
                    </>
                );

            default:
                return (
                    <>
                        <Button
                            type={"text"}
                            onClick={() => setPopoverContentConfig("language")}
                        >
                            {t("common.language")}
                        </Button>
                        <Button
                            type="text"
                            onClick={() => setPopoverContentConfig("theme")}
                        >
                            {t("common.theme")}
                        </Button>
                        <Button
                            type="text"
                            onClick={() => navigate("/profile")}
                        >
                            {t("common.bookmark")}
                        </Button>
                        {platformDowloadKey?.length ? (
                            <Button
                                type="text"
                                onClick={() => setPopoverContentConfig("download")}
                            >
                                {t("common.download")}
                            </Button>
                        ) : null}
                        {isMobile && (
                            <>
                                <Button
                                    type="text"
                                    onClick={() => {
                                        setOpenQrModal(true);
                                        setPopoverOpen(false);
                                    }}
                                >
                                    {t("referral.modal.title")}
                                </Button>
                                <Button
                                    type="text"
                                    onClick={() => navigate("/profile")}
                                >
                                    {t("common.editProfile")}
                                </Button>
                                <Button
                                    type="text"
                                    onClick={() => handleTokenExpiration()}
                                >
                                    {t("common.logOut")}
                                </Button>
                            </>
                        )}
                    </>
                );
        }
    };

    const popoverContentConfig = (
        <div>
            {popoverContentConfigKey && (
                <div className="popoverNestedHeader">
                    <Button
                        type="text"
                        className="popoverBackButton"
                        onClick={() => {
                            if (platformDowloadKey.some((k) => k[0] === popoverContentConfigKey)) {
                                setPopoverContentConfig("download");
                            } else {
                                setPopoverContentConfig();
                            }
                        }}
                    >
                        &lt;
                    </Button>
                    <div>
                        {popoverContentConfigKey === platformDowloadKey?.[0]?.[0] ? popoverContentConfigKey : t(`common.${popoverContentConfigKey}`)}
                    </div>
                </div>
            )}

            <div className="popover-select-item-list">{getNestedPopoverContent(popoverContentConfigKey)}</div>
        </div>
    );

    const formatMobileHeaderRoutes = () => {
        const mobileHeaderRoutes = {};
        mobileRoutes.forEach((item) => {
            const categoryName = item?.categoryName || "common"; // Set to "common" if categoryName is not defined
            if (!mobileHeaderRoutes[categoryName]) {
                mobileHeaderRoutes[categoryName] = [];
            }
            if (item?.path) mobileHeaderRoutes[categoryName].push(item);
        });

        return mobileHeaderRoutes;
    };

    const mobileHeaderRoutes = formatMobileHeaderRoutes();

    return (
        <Header id={"layout-header"}>
            {!isMobile ? (
                <Row justify={"space-between"} wrap={false}>
                    <Col>
                        <img
                            alt={""}
                            className={"clickable"}
                            onClick={() => navigate("/dashboard")}
                            style={{maxHeight: 40}}
                            src={colorState === "dark" ? brandSettingState?.companyLogoInverse : brandSettingState?.companyLogo}
                        />
                    </Col>
                    <div className="header-link-section">
                        {headerRoutes.map((r) => (
                            <Popover
                                className="header-link"
                                key={r?.key}
                                destroyTooltipOnHide
                                trigger="hover"
                                content={
                                    r?.sub?.length ? (
                                        <div className="popover-select-item-list menu-dropdown">
                                            {r.sub.map((s) => (
                                                <Button
                                                    type="text"
                                                    className="dropdown-link"
                                                    key={s?.key}
                                                    onClick={() => {
                                                        if (s?.path?.startsWith("http")) {
                                                            window.open(s?.path, "_blank");
                                                            return;
                                                        }
                                                        navigate(s?.path);
                                                    }}
                                                >
                                                    <IconModule
                                                        iconName={`md-${s?.label?.replaceAll(".", "")?.replaceAll(" ", "")?.toLowerCase()}`}
                                                        layout={1}
                                                    />
                                                    {t(`module.${s?.label?.replaceAll(" ", "_")?.replaceAll(".", "")?.toLowerCase()}`)}
                                                </Button>
                                            ))}
                                        </div>
                                    ) : null
                                }
                                getPopupContainer={() => document.querySelector("#layout-1")}
                            >
                                <div
                                    className="header-nav-group"
                                    onClick={() => {
                                        if (r?.path?.startsWith("http")) {
                                            window.open(r?.path, "_blank");
                                            return;
                                        }
                                        navigate(r?.path);
                                    }}
                                >
                                    <div>
                                        {t(`module.${r?.label?.replace(".", "")?.replace(/[-\s+]/g, "_").toLowerCase()}`)}
                                    </div>
                                    <div>{r?.sub?.length ? <DownOutlined/> : null}</div>
                                </div>
                            </Popover>
                        ))}
                    </div>
                    <div className="header-popover-section">
                        <Popover
                            trigger={"click"}
                            content={popoverContent}
                            open={popoverOpen}
                            onOpenChange={setPopoverOpen}
                            getPopupContainer={() => document.querySelector("#layout-1")}
                        >
                            <div className="header-profile-section">
                                <div className="header-avatar">
                                    <Avatar
                                        className="profile-avatar"
                                        size={34}
                                        src={userDetails?.profileImage || dummyImg}
                                    />
                                </div>
                                {userDetails.username}
                            </div>
                        </Popover>
                        <Popover
                            placement={"bottom"}
                            trigger={"click"}
                            content={<Announcement_1 isHeader/>}
                            getPopupContainer={() => document.querySelector("#layout-1")}
                        >
                            <div className="header-announcement">
                                <Badge count={unreadAnnouncementCount} size={"small"}>
                                    <IconCommon iconName={"cm-notification-regular"}/>
                                </Badge>
                            </div>
                        </Popover>
                        <Popover
                            trigger={"click"}
                            content={popoverContentConfig}
                            getPopupContainer={() => document.querySelector("#layout-1")}
                            onOpenChange={(e) => {
                                if (!e) setPopoverContentConfig();
                            }}
                        >
                            <div className="header-config">
                                <IconCommon iconName={"cm-setting-regular"}/>
                            </div>
                        </Popover>
                    </div>
                </Row>
            ) : (
                <Row justify={"space-between"} align={"middle"} wrap={false}>
                    <Col>
                        <img
                            alt={""}
                            className={"clickable"}
                            onClick={() => navigate("/dashboard")}
                            height={30}
                            src={colorState === "dark" ? brandSettingState?.siteFaviconInverse : brandSettingState?.siteFavicon}
                        />
                    </Col>
                    <Space>
                        <Popover
                            placement={"bottom"}
                            trigger={"click"}
                            content={<Announcement_1 isHeader/>}
                            getPopupContainer={() => document.querySelector("#layout-1")}
                        >
                            <div className="header-announcement">
                                <Badge count={unreadAnnouncementCount} size={"small"}>
                                    <IconCommon iconName={"cm-notification-regular"}/>
                                </Badge>
                            </div>
                        </Popover>
                        <IconCommon
                            onClick={() => setOpen(true)}
                            iconName={"cm-hamburger-regular"}
                            className={"header-icon"}
                        />
                    </Space>
                    <Drawer
                        className={"header-drawer"}
                        getContainer={() => document.querySelector("#layout-1")}
                        title={
                            <Row
                                className={"header-drawer-title"}
                                justify={"space-between"}
                                align={"middle"}
                                wrap={false}
                            >
                                <Col>
                                    <img
                                        alt={""}
                                        className={"clickable"}
                                        onClick={() => navigate("/dashboard")}
                                        height={30}
                                        src={colorState === "dark" ? brandSettingState?.siteFaviconInverse : brandSettingState?.siteFavicon}
                                    />
                                </Col>
                                <IconCommon
                                    onClick={() => setOpen(false)}
                                    iconName={"cm-close-regular"}
                                    className={"header-icon"}
                                />
                            </Row>
                        }
                        placement={"right"}
                        closable={false}
                        onClose={onClose}
                        open={open}
                        width={"100%"}
                    >
                        <Row justify={"space-between"} align={"top"}>
                            <div className="avatar-section">
                                <Avatar
                                    className="profile-avatar me-2"
                                    size={48}
                                    src={userDetails?.profileImage || dummyImg}
                                />
                                <div>
                                    <div className="username">
                                        {userDetails?.username}
                                    </div>
                                    <div className="accountTypeBadge">
                                        {t(`user.statusTag.${userDetails?.clientAccountType}`)}
                                    </div>
                                </div>
                            </div>
                            <Popover
                                placement={"bottomRight"}
                                trigger={"click"}
                                content={popoverContentConfig}
                                getPopupContainer={() => document.querySelector("#layout-1")}
                                onOpenChange={(e) => {
                                    if (!e) setPopoverContentConfig();
                                }}
                            >
                                <div className="header-config">
                                    <IconCommon iconName={"cm-setting-regular"}/>
                                </div>
                            </Popover>
                        </Row>
                        {userDetails?.clientAccountType !== "demo" ? (
                            <Button
                                className={"mt-3"}
                                type="primary"
                                block
                                onClick={() => setOpenGoLiveModal(true)}
                            >
                                {t("common.goLive")}
                            </Button>
                        ) : null}
                        <div className={"mt-3"}>
                            {Object.keys(mobileHeaderRoutes)?.map((item) => (
                                <div key={item} className={"mt-4"}>
                                    <p className={"drawer-section-title"}>{t(`common.${item}`)}</p>
                                    <Divider className={"mt-1 mb-3"}/>
                                    <Row gutter={[16, 16]}>
                                        {mobileHeaderRoutes[item]?.map((d) => (
                                            <Col
                                                span={8}
                                                key={d?.key}
                                                onClick={() => {
                                                    d?.type === "_blank" ? window.open(d?.path, "_blank") : navigate(d?.path);
                                                    setOpen(false);
                                                }}
                                            >
                                                {d?.icon}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <p className={"text-center mt-5"}>
                            <FooterTime/>
                        </p>
                    </Drawer>
                </Row>
            )}
            <QrModal
                isModalOpen={openQrModal}
                handleCancel={() => setOpenQrModal(false)}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <PlatformDownloadModal
                isModalOpen={openPlatformModal}
                handleCancel={() => setOpenPlatformModal(false)}
                platformData={platformData}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <GoLiveModal
                isModalOpen={openGoLiveModal}
                handleCancel={() => setOpenGoLiveModal(false)}
                registerField={registerField}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
        </Header>
    );
};
