import "./css/style.css"

export default function AlliumLoader() {

    return (
        <div id={"alliumLoader"}>
            <div className={"loader"}>
                <span className={"load-1 load"}/>
                <span className={"load-2 load"}/>
                <span className={"load-3 load"}/>
            </div>
        </div>
    );
}