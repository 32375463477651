import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_MARKET_NEWS = gql`
    query getMarketNews($param:JSON) {
        getMarketNews(param:$param)
    }
`;

export async function getMarketNews(page, rows = 24) {
    const lang = localStorage.getItem("language");
    let newsLanguage;
    let timeLanguage;
    switch (lang) {
        case "cn":
        case "zh":
            newsLanguage = "zh";
            timeLanguage = "zh-cn";
            break;

        default:
            newsLanguage = "en";
            timeLanguage = "en";
            break;
    }

    const variables = {
        param: {
            language: newsLanguage,
            page: page || 1,
            rows: rows,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_MARKET_NEWS, variables, false);
    return execute?.getMarketNews;
}