import React, {useEffect} from "react";
import {Badge, Breadcrumb, Button, Dropdown, Layout, Menu, Popover} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    globalBreadCrumbState,
    globalColorThemeState,
    globalLanguageListState,
    globalUnreadAnnouncementCountState,
    globalUserDetailState,
} from "../../../_common/global_state";
import {MenuOutlined} from "@ant-design/icons";
import {useIsMobile} from "../../../_common/function";
import {useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import {useVerification} from "../../../_common/_authorize";
import useLayoutFunction from "../../function";
import Announcement_6 from "../../../pages/announcement/view/layout_6";
import {getUserAnnouncementUnreadCount} from "../../../api/graphql/announcement";

export const Header_6 = (props) => {
    const {
        registerField,
        onCollapsedChange,
        setOpenGoLiveModal,
        setOpenQrModal,
        setOpenPlatformModal
    } = props;
    const isMobile = useIsMobile(768);
    const {t, i18n} = useTranslation();
    const {handleTokenExpiration} = useVerification();
    const {Header} = Layout;
    const userDetails = useRecoilValue(globalUserDetailState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const globalBreadCrumb = useRecoilValue(globalBreadCrumbState);
    const [unreadAnnouncementCount, setUnreadAnnouncementCount] = useRecoilState(globalUnreadAnnouncementCountState);
    const navigate = useNavigate();
    const {IconCommon, IconFlag} = useShared();
    const {changeLanguageHandler} = useLayoutFunction()

    const userId = localStorage.getItem("userid")

    const breadcrumbItems =
        Array.isArray(globalBreadCrumb) &&
        globalBreadCrumb?.length > 0 &&
        globalBreadCrumb?.map((item, index) => {
            return {
                title: (
                    <div
                        className={"clickable"}
                        style={
                            index === globalBreadCrumb.length - 1
                                ? {color: "var(--hover-active-color)"}
                                : {color: "var(--text-color)"}
                        }
                        onClick={() => navigate(item?.path)}
                    >
                        {t(
                            `module.${item?.name
                                ?.replace(".", "")
                                .replace(/[-\s+]/g, "_")
                                .toLowerCase()}`
                        )}
                    </div>
                ),
            };
        });


    const currentLanguageObj = languageListState?.find(
        (lang) => lang?.code === localStorage.getItem("language")
    );

    const languageDropdown = (
        <Menu
            className={"headerLanguage"}
            selectedKeys={[localStorage.getItem("language")]}
        >
            {languageListState?.map((lang) => (
                <Menu.Item
                    key={lang?.code}
                    value={lang?.code}
                    onClick={() => changeLanguageHandler(lang?.code)}
                >
                    {lang?.nativeName}
                </Menu.Item>
            ))}
        </Menu>
    );

    const preload = async () => {
        const res = await getUserAnnouncementUnreadCount(userId);
        setUnreadAnnouncementCount(res?.getUserAnnouncementByUserId?.unreadTotal);
    };

    useEffect(() => {
        preload();
    }, []);

    return (
        <Header id={"layout-header"}>
            <div className="layout-header">
                {isMobile ? (
                    <>
                        <Button
                            type="text"
                            className=""
                            icon={<MenuOutlined/>}
                            onClick={() => onCollapsedChange(false)}
                        />
                        <Popover
                            trigger={["click"]}
                            content={<Announcement_6 isHeader/>}
                            id={"header-announcement"}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        >
                            <div
                                className={"headerSpan"}
                                onClick={(e) => e?.preventDefault()}
                            >
                                <Badge count={unreadAnnouncementCount} size={"small"}>
                                    <IconCommon
                                        iconName={"cm-notification-regular"}
                                        className={"toggleIcon"}
                                        style={{width: "26px"}}
                                    />
                                </Badge>
                            </div>
                        </Popover>
                    </>
                ) : (
                    <>
                        <Breadcrumb
                            className="layout-header-breadcrumb"
                            items={breadcrumbItems}
                        />
                        <div className="layout-header-sub">
                            {userDetails?.clientAccountType === "demo" &&
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenGoLiveModal(true);
                                }}
                            >
                                {t("common.goLive")}
                            </Button>
                            }
                            <Button
                                type={"primary"}
                                className={""}
                                onClick={() => {
                                    setOpenPlatformModal(true);
                                }}
                                icon={
                                    <IconCommon
                                        isAlwaysInActive
                                        iconName={"cm-download-regular"}
                                        style={{width: "16px"}}
                                    />
                                }
                            >
                                {t("module.platformDownload")}
                            </Button>
                            {/*{false ? <Input placeholder="CTRL + K"/> : null}*/}
                            <div className="username">{userDetails.username}</div>
                            <Dropdown
                                overlay={languageDropdown}
                                id={"language-select"}
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                arrow
                            >
                                <div
                                    className={"headerSpan"}
                                    onClick={(e) => e?.preventDefault()}
                                >
                                    <IconFlag
                                        countryCode={currentLanguageObj?.countryCode}
                                        style={{height: "24px", borderRadius: "10px"}}
                                    />
                                </div>
                            </Dropdown>
                            <Popover
                                trigger={["click"]}
                                content={<Announcement_6 isHeader/>}
                                id={"header-announcement"}
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            >
                                <div
                                    className={"headerSpan"}
                                    onClick={(e) => e?.preventDefault()}
                                >
                                    <Badge count={unreadAnnouncementCount} size={"small"}>
                                        <IconCommon
                                            iconName={"cm-notification-regular"}
                                            className={"toggleIcon"}
                                            style={{width: "26px"}}
                                        />
                                    </Badge>
                                </div>
                            </Popover>
                            <div
                                onClick={() => {
                                    if (colorState === "light") {
                                        setColorState("dark");
                                        localStorage.setItem("color", "dark");
                                    } else {
                                        setColorState("light");
                                        localStorage.setItem("color", "light");
                                    }
                                }}
                            >
                                {colorState !== "light" ? (
                                    <IconCommon
                                        iconName={"cm-lighttheme-regular"}
                                        className={"toggleIcon"}
                                        style={{width: "26px"}}
                                    />
                                ) : (
                                    <IconCommon
                                        iconName={"cm-darktheme-regular"}
                                        className={"toggleIcon"}
                                        style={{
                                            width: "26px",
                                        }}
                                    />
                                )}
                            </div>
                            <div>
                                <IconCommon
                                    style={{width: "26px"}}
                                    iconName={"cm-qr-regular"}
                                    onClick={() => {
                                        setOpenQrModal(true);
                                    }}
                                />
                            </div>
                            <div>
                                <IconCommon
                                    style={{height: "26px"}}
                                    iconName={"cm-logout-regular"}
                                    onClick={() => handleTokenExpiration()}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Header>
    );
};
