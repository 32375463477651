import "./css/style.css";

export default function RidgewaymarketsLoader(props) {
    // size = "default" | "small" | "medium" | "large"
    const {size} = props;
    return (
        <div id={"ridgewaymarketsLoader"}>
            <div className="loaderContainer">
                <div className={`loader ${size || "default"}Loader`}></div>
            </div>
        </div>
    );
}
