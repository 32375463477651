import "./css/style.css";

export default function BlueOceanLoader({ size }) {
  return (
    <div id={"blue-ocean-loader"}>
        <div className={"loader-wrapper"}>
            <div className={"loader"}>
                <span />
            </div>
        </div>
    </div>
  );
}
