import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_USER_ANNOUNCEMENT_BY_USER_ID = gql`
    query getUserAnnouncementByUserId($id: String, $limit: Int, $offset: Int, $orderBy: String) {
        getUserAnnouncementByUserId(id: $id, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserAnnouncementWithUnreadAry {
                total
                unreadTotal
                data {
                    _id
                    announcementId
                    dataOfObject {
                        title
                        content
                        description
                        language
                    }
                    isRead
                    createdAt
                    updatedAt
                    announcementUpdatedAt
                    isAnnouncementUpdated
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getUserAnnouncementByUserId(userid, limit, offset, orderBy) {
    const variables = {
        id: userid,
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(GET_USER_ANNOUNCEMENT_BY_USER_ID, variables, false);

    return execute;
}

const UPDATE_USER_ANNOUNCEMENT_READ_STATUS = gql`
    mutation updateUserAnnouncementReadStatus($id: [String]!, $isRead: Boolean!) {
        updateUserAnnouncementReadStatus(id: $id, isRead: $isRead)
    }
`;

export async function updateUserAnnouncementReadStatus(id, isRead) {
    const variables = {
        id: id,
        isRead: isRead,
    }

    const execute = await ApolloClientQuery(UPDATE_USER_ANNOUNCEMENT_READ_STATUS, variables, true);

    return execute;
}

const GET_USER_ANNOUNCEMENT_UNREAD_COUNT = gql`
    query getUserAnnouncementByUserId($id: String, $limit: Int, $offset: Int, $orderBy: String) {
        getUserAnnouncementByUserId(id: $id, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserAnnouncementWithUnreadAry {
                unreadTotal
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getUserAnnouncementUnreadCount(userid) {
    const variables = {
        id: userid,
        limit: null,
        offset: null,
        orderBy: null,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(GET_USER_ANNOUNCEMENT_UNREAD_COUNT, variables, false);

    return execute;
}
