import {useRecoilValue} from "recoil";
import {globalBrandSettingState} from "./global_state";
import moment from "moment";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function formatMoney(amount) {
    let amountParse = parseFloat(amount);
    if (!isNaN(amountParse)) {
        return amountParse?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
        return "-";
    }
}

export function convertToFullUpperCase(inputString) {
    const words = inputString.split(" ");
    const capitalizedWords = words.map((word) => word.toUpperCase());
    return capitalizedWords.join(" ");
}

export function convertToUppercase(inputString) {
    const words = inputString.split(" ");
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
}

export function checkDateFormat(dateString) {
    const today = new Date();
    const date = new Date(
        dateString.substr(0, 4),
        parseInt(dateString.substr(4, 2)) - 1,
        dateString.substr(6, 2)
    );

    if (date.toDateString() === today.toDateString()) {
        return "Today";
    }

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
    }

    return date.toISOString().substr(0, 10);
}

export function FormattedISODate({dateString, forceDate}) {
    const {t} = useTranslation();
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const gmtTime = moment(dateString)?.utcOffset(+brandSettingState?.timezone);
    const currentTime = moment()?.utcOffset(+brandSettingState?.timezone);
    const isToday = currentTime.isSame(gmtTime, "day");
    const isYesterday = currentTime
        .clone()
        .subtract(1, "day")
        .isSame(gmtTime, "day");

    let timeDisplay = "";

    if (isToday) {
        if (forceDate) {
            return `${t("common.today")} ${gmtTime.format("hh:mm a")}`;
        }
        const duration = moment.duration(currentTime.diff(gmtTime));
        if (duration.asMinutes() < 60) {
            timeDisplay = `${Math.floor(duration.asMinutes())} ${t(
                "date.minutesAgo"
            )}`;
        } else {
            timeDisplay = `${Math.floor(duration.asHours())} ${t("date.hoursAgo")}`;
        }
    } else if (isYesterday) {
        timeDisplay = `${t("common.yesterday")} ${gmtTime.format("hh:mm a")}`;
    } else {
        timeDisplay = gmtTime.format("DD MMM YYYY, hh:mm a");
    }

    return timeDisplay;
}

export function formatISODate(dateString) {
    const date = new Date(dateString);

    if (isNaN(date)) {
        return "Invalid date";
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return `Today ${date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        })}`;
    }

    if (date.toDateString() === yesterday.toDateString()) {
        return `Yesterday ${date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        })}`;
    }

    return `${date?.toISOString()?.substr(0, 10)} ${date?.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    })}`;
}

export function truncateString(str, maxLength) {
    if (str?.length <= maxLength) {
        return str;
    }

    return str?.substring(0, maxLength) + "...";
}

export function emailFormat(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function noSymbolsNumbers(input) {
    const alphabetRegex = /^[a-zA-Z]*$/;
    return alphabetRegex.test(input);
}

export function onlyNumbers(input) {
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(input);
}

export function moduleTranslation(inputString) {
    const result = inputString
        .replace(/[./]/g, "")
        .replace(/[-\s+]/g, "_")
        .toLowerCase();
    return "module." + result;
}

export function commonTranslation(inputString) {
    // const result = inputString.replace(".", "").replace(/[-\s+]/g, "_").toLowerCase();
    return "common." + inputString;
}

export function userTranslation(inputString) {
    // const result = inputString.replace(".", "").replace(/[-\s+]/g, "_").toLowerCase();
    return "user." + inputString;
}

export const handleExportToExcel = async (
    tableData,
    tableColumn,
    tableName,
    fileType = "xlsx"
) => {
    try {
        const transformedData = await tableData?.map((item) => {
            const newItem = {};
            tableColumn.forEach((column) => {
                if (!column.dataIndex) return;
                const key = Array.isArray(column.dataIndex) ? column.dataIndex.join("_") : column.dataIndex;
                newItem[column.title] = item[key];
            });
            return newItem;
        });

        if (!transformedData) {
            return null;
        }

        var fileFormat =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
        if (fileType === "csv") {
            fileFormat = "text/csv;charset=utf-8";
        }

        var tempFile = transformedData.map((item) => {
            delete item?.ids;
            return item;
        });

        let header = Object.keys(tempFile[0] || []);
        const index = header.findIndex((item) => item === "en");
        if (index !== -1) {
            const item = header.splice(index, 1)[0];
            header.splice(1, 0, item);
        }

        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, tempFile, {origin: "A2", skipHeader: true});

        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const buffer = XLSX.write(wb, {
            bookType: fileType,
            type: "array",
            cellStyles: true,
        });

        const fileFormatBlob = new Blob([buffer], {type: fileFormat});
        const currentDate = moment().format("YYYYMMDDHHmm");

        if (fileFormatBlob) {
            FileSaver.saveAs(fileFormatBlob, `${tableName}_${currentDate}`);
        }
    } catch (e) {
        console.log("Error:", e);
    }
};

export const useIsMobile = (maxWidth = 767) => {
    const [isMobile, setIsMobile] = useState(null);
    useEffect(() => {
        const mediaQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);

        setIsMobile(mediaQuery.matches);

        const handleResize = (e) => setIsMobile(e.matches);
        mediaQuery.addEventListener("change", handleResize);

        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    return isMobile;
};

export const useIsTablet = (maxWidth = 1024) => {
    const [isTablet, setIsTablet] = useState(null);
    useEffect(() => {
        const mediaQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);

        setIsTablet(mediaQuery.matches);

        const handleResize = (e) => setIsTablet(e.matches);
        mediaQuery.addEventListener("change", handleResize);

        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    return isTablet;
};


export const renderSymbolIcon = (symbolName) => {
    return (
        <div className={"default-symbol-icon-container d-flex"}>
      <span className={"default-symbol-icon m-auto"}>
        {symbolName?.slice(0, 1)}
      </span>
        </div>
    );
};

export const onChangePageHandler = (
    page,
    pageSize,
    setLimit,
    setOffset,
    limit
) => {
    setLimit(pageSize);
    setOffset(limit * (page - 1));
};

export const transformArrayToObject = (array) => {
    const result = {};
    const platform = localStorage.getItem("platform")
    const symbol = platform === "cTrader" ? "symbol" : "symbolMt5"

    array.forEach((item) => {
        const {categoryName, parent, label, type} = item?.category;

        if (parent) {
            const parentCategory = parent.categoryName;
            if (!result[parentCategory]) {
                result[parentCategory] = {
                    symbol: [],
                    symbolMt5: [],
                    children: [],
                    label: parent?.label,
                    type,
                };
            }
            result[parentCategory]?.[symbol].push(...item?.[symbol]);
            result[parentCategory].children.push({categoryName, label});
        } else {
            if (!result[categoryName]) {
                result[categoryName] = {
                    symbol: [],
                    symbolMt5: [],
                    children: [],
                    label,
                    type,
                };
            }
            result[categoryName]?.[symbol]?.push(...item?.[symbol]);
        }
    });

    return result;
};

const KEY = "EoGJqWIcDqqyNlD2iTwgyuihFiNCCfIad9hKpbY1Jds="
const IV = "adO033BWgwbwxAWcieH/2A=="

export const encryptMT5Message = (plainText) => {
    let message
    if (typeof plainText === "string") message = plainText
    else message = JSON.stringify(plainText)

    // const key = crypto.enc.Base64.parse(KEY);
    // const iv = crypto.enc.Base64.parse(IV);

    // return crypto.AES.encrypt(message, key, {iv: iv}).toString();

    return btoa(message)
}

export const decryptMT5Message = (cipherText) => {
    // const key = crypto.enc.Base64.parse(KEY);
    // const iv = crypto.enc.Base64.parse(IV);
    // const decrypted = crypto.AES.decrypt(cipherText, key, {iv: iv});
    //
    // const decryptedString = decrypted.toString(crypto.enc.Utf8);
    //
    // return JSON.parse(decryptedString)
    return JSON.parse(atob(cipherText))
}

export function formatDateTimeToGMT(dateTimeString, format = "YYYY-MM-DD") {
    let timezone = 0

    if (moment(dateTimeString).isValid()) {
        return moment(dateTimeString).utcOffset(+timezone, false).format(format)
    } else return "-"
}

export function formatDateTime(dateTimeString, format = "YYYY-MM-DD") {
    let timezone = 0

    if (localStorage.getItem("timezone")) {
        timezone = localStorage.getItem("timezone")
    }

    if (moment(dateTimeString).isValid()) {
        return moment(dateTimeString).utcOffset(-timezone, false).format(format)
    } else return "-"
}

export function formatDate(dateString, isDisplay = false, format = "YYYY-MM-DD") {
    if (dayjs(dateString).isValid()) {
        if (isDisplay) {
            return dayjs(dayjs(dateString).utcOffset(0, false)).format(format)
        } else if ((typeof dateString === "object") || (/^\d{4}-\d{2}-\d{2}T/.test(dateString))) {
            return dayjs(dateString).utcOffset(0, false).hour(0).minute(0).second(0).millisecond(0)
        } else if (dateString) {
            return dayjs(dateString.toISOString().split("T")[0].concat("T00:00:00.000Z")).utcOffset(0, false).hour(0).minute(0).second(0).millisecond(0)
        } else {
            return dayjs(dayjs()).utcOffset(0, false).hour(0).minute(0).second(0).millisecond(0)
        }
    }
}
