//getBaseInstrument
//getSpreadGroupSymbolPriceCT
//getSpreadGroupSymbolPriceMT

import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_BASE_INSTRUMENT = gql`
    query getBaseInstrument($param:JSON) {
        getBaseInstrument(param:$param)
    }
`;

export async function getInstruments(symbol) {
    const regex = /^[,\s]*$/;

    if (!regex.test(symbol)) {
        const variables = {
            param: {
                base_symbol_id: symbol,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        };
        const execute = await ApolloClientQuery(GET_BASE_INSTRUMENT, variables, false);
        return execute?.getBaseInstrument;
    }
}