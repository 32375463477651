import React, {useEffect, useState} from "react";
import {Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import AnnouncementListing_5 from "./component/announcementListing";
import {getUserAnnouncementByUserId} from "../../../../api/graphql/announcement";
import {globalNotificationState, globalUnreadAnnouncementCountState} from "../../../../_common/global_state";
import {useSetRecoilState} from "recoil";
import "./css/style.css"

const Announcement_5 = (props) => {
    const {isHeader} = props
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const setUnreadAnnouncementCount = useSetRecoilState(globalUnreadAnnouncementCountState);
    const [total, setTotal] = useState(0)
    const [data, setData] = useState({});
    const [selectedCategory, setSelectedCategory] = useState("announcement")

    const userId = localStorage.getItem("userid")

    const preload = async () => {
        try {
            const response = await getUserAnnouncementByUserId(userId, 8, 0);
            setData(response?.getUserAnnouncementByUserId?.data);
            setUnreadAnnouncementCount(response?.getUserAnnouncementByUserId?.unreadTotal);
            setTotal(response?.getUserAnnouncementByUserId?.total);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the platform download page. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    }

    useEffect(() => {
        preload()
    }, [])

    const categoryOptions = [
        {label: "Announcement", value: "announcement"},
        // {label: "Notification", value: "notification"}
    ]

    let component

    switch (selectedCategory) {
        case "announcement":
            component = <AnnouncementListing_5 isHeader={isHeader} data={data} total={total}/>
        default:
            component = <AnnouncementListing_5 isHeader={isHeader} data={data} total={total}/>
    }

    return (
        <div id={"announcement"}>
            <Space>
                <Select
                    options={categoryOptions}
                    className={"dropdown-select"}
                    value={selectedCategory}
                    onChange={(value) => setSelectedCategory(value)}
                />
            </Space>
            {component}
        </div>

        // <Tabs
        //             id={"announcement"}
        //             defaultActiveKey="1"
        //             items={items}
        //         />
    );
}

export default Announcement_5