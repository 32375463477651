import {ApolloClientQuery} from "./_setup";
import {gql} from "@apollo/client";

const GET_TRADE_IDEAS = gql`
    query getTradeIdeas($param:JSON) {
        getTradeIdeas(param:$param)
    }
`;

export async function getOpportunities(id, keyword, baseSymbolId, rows) {
    const lang = localStorage.getItem("language");
    let languageForCulture;
    switch (lang) {
        case "en":
            languageForCulture = "EN";
            break;
        case "vn":
            languageForCulture = "VI";
            break;
        case "cn":
        case "zh-H":
            languageForCulture = "ZH_CN";
            break;
        case "ms":
            languageForCulture = "MS";
            break;
        case "th":
            languageForCulture = "TH";
            break;
        default:
            languageForCulture = "EN";
            break;
    }
    let params = {
        culture: languageForCulture,
        // type_product: stock || "",
        baseSymbolId: baseSymbolId,
        page: 1,
    };

    if (rows) {
        params["rows"] = rows;
    }

    if (id) {
        params["user_id"] = id;
    }

    if (keyword && keyword !== "") {
        params["keyword"] = keyword;
    }

    params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value != null));

    const variables = {
        param: JSON.parse(JSON.stringify(params)),
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(GET_TRADE_IDEAS, variables, false);
    return execute?.getTradeIdeas;
}