import "./css/style.css"

export default function AntosLoader({size}) {
    return (
        <div id={"antos-loader"}>
            <div className="loader_container d-flex justify-content-center align-items-center">
                <div className="loader">
                    <div className="load">
                        <div className="load__bar load__bar--1"/>
                        <div className="load__bar load__bar--2" />
                        <div className="load__bar load__bar--3" />
                        <div className="load__bar load__bar--4" />
                        <div className="load__bar load__bar--5" />
                    </div>

                    <div className="load">
                        <div className="load__bar load__bar--1-inv" />
                        <div className="load__bar load__bar--2-inv" />
                        <div className="load__bar load__bar--3-inv" />
                        <div className="load__bar load__bar--4-inv" />
                        <div className="load__bar load__bar--5-inv" />
                    </div>
                </div>
            </div>
        </div>
    );
}
