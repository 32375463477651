import "./css/style.css"

export default function CronoscapLoader() {
    return (
        <div id={"cronoscapLoader"}>
            <div className={"loader-wrapper"}>
                <div className={"loader"}>
                    <span />
                </div>
            </div>
        </div>
    );
}
