import "./css/style.css"

export default function MinosLoader() {
    return (
        <div id={"minosLoader"}>
            <div className={"loader-wrapper"}>
                <div className={"loader"}>
                    <span />
                </div>
            </div>
        </div>
    );
}
