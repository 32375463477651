import "./css/style.css";

export default function FundamarketsLoader({ size }) {
  return (
    <div id={"fundamarketsLoader"}>
      <div className={"loader"}>
        Loading ...
        <span />
      </div>
    </div>
  );
}
