import {Button, Checkbox, Col, DatePicker, Drawer, Form, Input, InputNumber, Radio, Row, Select, Space, Upload,} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {globalUserDetailState} from "../../../../_common/global_state";
import {PlusOutlined} from "@ant-design/icons";
// import moment from "moment";
import useLayoutFunction from "../../../function";
// import dayjs from "dayjs";
import {formatDate} from "../../../../_common/function";

const GoLiveModal = (props) => {
    const {isModalOpen, handleCancel, registerField} = props;
    const [form] = Form.useForm();

    const [btnLoading, setBtnLoading] = useState(false);
    const [countryCode, setCountryCode] = useState(null);

    const userDetails = useRecoilValue(globalUserDetailState);

    const {goLiveFunction} = useLayoutFunction();

    const sortedField = [...registerField]
        ?.filter(
            (v) =>
                v?.fieldList !== null &&
                v.fieldList.fieldName !== "checkTermAndCondition" &&
                v.fieldList.fieldName !== "affiliateCode" &&
                v.fieldList.category !== "loginInformation"
        )
        .sort((a, b) => a.position - b.position);

    const onFinish = async (values) => {
        await setBtnLoading(true);

        if (countryCode) {
            values.countryCode = countryCode;
        }
        Object.keys(values).map((value) => {
            const getField = sortedField.find(
                (item) => item?.fieldList?.fieldName === value
            );
            if (getField?.fieldList?.fieldType === "file") {
                values[value] = values[value].file.response[0].link.toString();
            } else if (getField?.fieldList?.fieldType === "date") {
                // values[value] = moment(values[value]).toISOString()
                values[value] = formatDate(values[value]).toISOString()
            }
        });

        values = {
            ...values,
            clientAccountType: "live",
        };

        // const response = await goLiveFunction(values);
        setBtnLoading(false);
        handleCancel();
    };

    const {t} = useTranslation();

    useEffect(() => {
        setCountryCode(userDetails?.countryCode)
    },[userDetails])

    return (
        <Drawer
            title={null}
            className={"goLiveModal"}
            size={"large"}
            open={isModalOpen}
            closeIcon={null}
            onClose={handleCancel}
            getContainer={() => document.getElementById("layout-3")}
            destroyOnClose
        >
            <h1 className={"mb-4"}>{t("common.goLive")}</h1>
            <div>
                <Form
                    className={"goLiveForm"}
                    name="basic"
                    form={form}
                    layout={"vertical"}
                    labelCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Row gutter={[16, 16]}>
                        {sortedField?.map((register) => {
                            let component;
                            let defaultValue

                            if (register?.fieldList?.fieldType === "date") {
                                // defaultValue = null
                                defaultValue = !userDetails?.[register?.fieldList?.fieldName]
                                    ? null
                                    // : dayjs(dayjs(userDetails?.[register?.fieldList?.fieldName])?.utc()?.format("YYYY-MM-DD") + "T12:00:00+00:00");
                                    : formatDate(userDetails?.[register?.fieldList?.fieldName])
                            } else {
                                defaultValue = register?.fieldList?.fieldName !== "affiliateCode" ? userDetails?.[register?.fieldList?.fieldName] : ""
                            }
                            switch (register?.fieldList?.fieldType) {
                                case "mobile":
                                    const prefix = (
                                        <Select
                                            className={"mobileSelector"}
                                            style={{
                                                width: 90,
                                                textAlign: "left",
                                            }}
                                            showSearch
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                            onChange={(e) => setCountryCode(e)}
                                            autoComplete={"off"}
                                            value={countryCode}
                                            popupMatchSelectWidth={false}
                                            options={register?.fieldList?.optionValue?.map(
                                                (d) => ({
                                                    ...d,
                                                    label: t(d?.label),
                                                    key: d?.label,
                                                })
                                            )}
                                            optionFilterProp={"label"}
                                            optionLabelProp={"value"}
                                        />
                                    );

                                    component = (
                                        <Input
                                            className={"mobileInput"}
                                            addonBefore={prefix}
                                            style={{width: "100%"}}
                                        />
                                    );

                                    break;
                                case "text":
                                    component = (
                                        <span className={"registerAccount"}>
                      <Input autoComplete={"off"} defaultValue={defaultValue}/>
                    </span>
                                    );
                                    break;
                                case "textarea":
                                    component = <Input.TextArea/>;
                                    break;
                                case "date":
                                    component = <DatePicker/>;
                                    break;
                                case "file":
                                    component = (
                                        <Upload action="/upload.do" listType="picture-card">
                                            <div>
                                                <PlusOutlined/>
                                                <div style={{marginTop: 8}}>Upload</div>
                                            </div>
                                        </Upload>
                                    );
                                    break;
                                case "dropdown":
                                    component = (
                                        <Select showSearch getPopupContainer={(triggerNode) => triggerNode.parentElement}>
                                            {register?.fieldList?.optionValue.map((value) => {
                                                return (
                                                    <Select.Option
                                                        key={value?.value}
                                                        value={value?.value}
                                                    >
                                                        {t(value?.label)}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    );
                                    break;
                                case "radioButton":
                                    component = (
                                        <Radio.Group>
                                            {register?.fieldList?.optionValue.map((value) => {
                                                return (
                                                    <Radio value={value?.value} key={value?.value}>
                                                        {t(value?.label)}
                                                    </Radio>
                                                );
                                            })}
                                        </Radio.Group>
                                    );
                                    break;
                                case "checkbox":
                                    component = <Checkbox/>;
                                    break;
                                case "checkboxGroup":
                                    component = (
                                        <Checkbox.Group
                                            options={register?.fieldList?.optionValue}
                                        />
                                    );
                                    break;
                                case "password":
                                    component = <Input.Password autoComplete={"off"}/>;
                                    break;
                            }

                            // This is required to prevent nested input control bug
                            // if (register?.fieldList?.fieldType === "mobile") {
                            //     return (
                            //         <Col md={12} xs={24} key={register?.fieldList?.fieldName}>
                            //             {component}
                            //         </Col>
                            //     );
                            // }

                            const rules = [
                                {
                                    required: register?.fieldList?.isRequired,
                                    message: t("errorMsg.required.pleaseFillIn", {
                                        field: t(register?.fieldList?.label),
                                    }),
                                }
                            ]

                            if (register?.fieldList?.fieldName?.includes("email")) {
                                rules.push({
                                    type: "email",
                                    message: t("errorMsg.type.email"),
                                })
                            }

                            return (
                                <Col md={12} xs={24} key={register?.fieldList?.fieldName}>
                                    <Form.Item
                                        label={t(register?.fieldList?.label)}
                                        name={register?.fieldList?.fieldName}
                                        rules={rules}
                                        initialValue={defaultValue}
                                        // shouldUpdate={(prevValues, curValues) => {
                                        //     console.log(prevValues, curValues);
                                        //     return prevValues !== curValues;
                                        // }}
                                    >
                                        {component}
                                    </Form.Item>
                                </Col>
                            );
                        })}
                    </Row>
                    <Col md={24} xs={24} className={"registerBtn text-end mt-5"}>
                        <Space>
                            <Button
                                onClick={() => handleCancel()}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                                loading={btnLoading}
                                disabled={btnLoading}
                            >
                                {t("common.submit")}
                            </Button>
                        </Space>
                    </Col>
                </Form>
            </div>
        </Drawer>
    );
};

export default GoLiveModal;
