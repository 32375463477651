import {verifyAccessTokenMutation, verifyCryptoAddress} from "../api/graphql/auth";
import {useNavigate} from "react-router-dom";
import {useRecoilState, useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {
    globalBrandModuleSettingLoadingState,
    globalInitLoadingState, globalIsLoginState,
    globalNotificationState,
    globalRunAuthState,
    globalUserDetailState,
    globalUserVerifyState,
    globalUserWalletState,
} from "./global_state";
import {getUserById} from "../api/graphql/user";
import {getEwalletById} from "../api/graphql/eWallet";
import axios from "axios";
import {fetchFCMToken} from "../_utilities/firebase";
import usePreload from "./_preload";

const useVerification = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [userVerifyState, setUserVerifyState] = useRecoilState(globalUserVerifyState);
    const setUserDetailState = useSetRecoilState(globalUserDetailState);
    const setInitLoading = useSetRecoilState(globalInitLoadingState);
    const setUserWalletState = useSetRecoilState(globalUserWalletState);
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const setRunAuthState = useSetRecoilState(globalRunAuthState);
    const setBrandModuleSettingLoading = useSetRecoilState(globalBrandModuleSettingLoadingState);
    const [isLoginState, setIsLoginState] = useRecoilState(globalIsLoginState)
    const {preload, getInitialData} = usePreload();

    const authorizeUser = async (accessToken, userid) => {
        const hash = window.location.hash;
        const currentPath = hash.substring(1);

        let userDetail;
        const tokenExpiration = await checkTokenExpiration(accessToken);

        if (tokenExpiration === "verify" && userid !== null) {
            try {
                userDetail = await getUserById(userid);
                // await getBrandModuleSetting();
                if (!isLoginState) {
                    await getInitialData();
                }
                setIsLoginState(false)
            } catch (e) {
                handleTokenExpiration();
            }
        }

        if (tokenExpiration === "verify" && userDetail?.getUserByID?._id) {
            try {
                localStorage.setItem("checked", "true")
                setRunAuthState(true);
                setUserVerifyState(true);
                setUserDetailState(userDetail?.getUserByID);
                setInitLoading(false)

                const eWallet = await getEwalletById(userDetail?.getUserByID?.eWallet?._id);
                //hardcore
                // const symbolCategory = await getSymbolMappingCategory(
                //     "cTrader-blueocean"
                // );
                //
                // await setSymbolCategoryState(symbolCategory?.getSymbolMappingCategory?.data);
                await setUserWalletState(eWallet?.getEWalletByID);

                if (currentPath === "/" || currentPath.includes("/login") || !currentPath) {
                    if (userDetail.status === "pending verification") {
                        navigate("/account-verification");
                    } else {
                        navigate("/dashboard");
                    }
                }
            } catch (e) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "System Error,Authorize Failed",
                    key: "systemNotification",
                });
            }

            await preload();
        } else {
            setInitLoading(false)
            setBrandModuleSettingLoading(false)
            handleTokenExpiration();
        }
    };

    const checkTokenExpiration = async (verifyToken) => {
        try {
            if (verifyToken) {
                const verify = await verifyAccessTokenMutation(verifyToken);
                if (verify?.verifyAccessToken?.login === false) {
                    handleTokenExpiration();
                    return "failed";
                } else {
                    return "verify";
                }
            } else {
                handleTokenExpiration();
                return "failed";
            }
        } catch (e) {
            // handleTokenExpiration();
            return "failed";
        }
    };

    const trackUserActivity = (timeoutInMinutes, logoutFunction) => {
        let timer;
        let isLoggedIn = true;
        let isPageVisible = true;
        const broadcastChannel = new BroadcastChannel("userActivityChannel"); // Create a new Broadcast Channel

        const expiredFunction = () => {
            if (isLoggedIn && isPageVisible) {
                isLoggedIn = false; // Set the flag to false to indicate user logout
                clearTimeout(timer);
                logoutFunction();
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "Session Expired",
                    key: "sessionExpired",
                });
            }
        };

        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(expiredFunction, timeoutInMinutes);
        };

        const clearTimer = () => {
            clearTimeout(timer);
        };

        const activityHandler = () => {
            localStorage.setItem("_pat", localStorage.getItem("_cat"))
            localStorage.setItem("_cat", Date.now())
            resetTimer();
            broadcastChannel.postMessage("userActive"); // Notify other tabs that user is active
        };

        // Add event listeners for user activity
        window.addEventListener("mousemove", activityHandler);
        window.addEventListener("keydown", activityHandler);
        window.addEventListener("mousedown", activityHandler);

        // Listen for broadcast messages from other tabs
        broadcastChannel.onmessage = (event) => {
            if (event.data === "userActive") {
                resetTimer(); // Reset the timer when a broadcast message is received from another tab indicating user activity
            }
        };

        // Initialize the timer
        resetTimer();

        // Function to stop tracking user activity and clear the timer
        const stopTrackingUserActivity = () => {
            isLoggedIn = false;
            window.removeEventListener("mousemove", activityHandler);
            window.removeEventListener("keydown", activityHandler);
            window.removeEventListener("mousedown", activityHandler);
            broadcastChannel.close(); // Close the Broadcast Channel when stopping tracking
            clearTimer();
        };

        // Return the function to stop tracking user activity
        return stopTrackingUserActivity;
    };

    const startTokenExpirationCheck = async (intervalInMinutes) => {
        let tokenExpirationInterval;
        const token = localStorage.getItem("a_t");

        async function verifyToken() {
            const tokenLatest = localStorage.getItem("a_t");
            const latestChecked = localStorage.getItem("checked")

            if (tokenLatest && !latestChecked) {
                const result = await checkTokenExpiration(tokenLatest);

                if (result === "failed") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: "Session Expired",
                        key: "systemNotification",
                    });
                    clearInterval(tokenExpirationInterval);
                }
            } else {
                if (latestChecked) localStorage.removeItem("checked")

                clearInterval(tokenExpirationInterval);
            }
        }

        if (token) {
            verifyToken();
            tokenExpirationInterval = setInterval(verifyToken, intervalInMinutes);
        }

        function stopTokenExpirationCheck() {
            clearInterval(tokenExpirationInterval);
        }

        return stopTokenExpirationCheck;
    };

    const trackUserLogout = async () => {
        const logoutChannel = new BroadcastChannel("logoutChannel");
        await logoutChannel.postMessage("logout");
        const currentURL = window.location.href;
        const hashIndex = currentURL.indexOf("#");
        const queryStringIndex = currentURL.indexOf("?");
        const currentPath = currentURL.substring(hashIndex + 1, queryStringIndex !== -1 ? queryStringIndex : undefined);

        logoutChannel.onmessage = (event) => {
            if (event.data === "logout") {
                if (currentPath !== "/register" && currentPath !== "/forgot-password" && currentPath !== "/verify-email") {
                    navigate("/login");
                }
            }
        };
    };

    const handleTokenExpiration = async () => {
        try {
            setUserVerifyState(false);
            localStorage.setItem("userid", "");
            localStorage.setItem("a_t", "");
            localStorage.setItem("_pat", "")
            localStorage.setItem("_cat", "")
            setRunAuthState(false);
        } catch (e) {
            console.log(e);
        } finally {
            await trackUserLogout();
        }
    };

    const baseErrorChecking = async (errorKey, additionalMsg = "") => {
        if (errorKey === "admin.auth.err.token_expired") {
            await setNotificationMessage({
                status: "error",
                title: "",
                msg: "Token Expired, please login again!",
                key: "systemNotificationGraphql",
            });
            await handleTokenExpiration();
        } else {
            await setNotificationMessage({
                status: "error",
                title: "",
                msg: additionalMsg ? t(errorKey) + additionalMsg : t(errorKey),
                key: "systemNotificationGraphql",
            });
        }

        return {
            status: "fail",
        };
    };

    const userGhostLogin = async (token) => {
        if (token) {
            const verify = await verifyAccessTokenMutation(token);
            if (verify?.verifyAccessToken?.login === false) {
                handleTokenExpiration();
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "System Error,Fail to verify token",
                    key: "failVerify",
                });
            } else {
                await localStorage.setItem("userid", verify?.verifyAccessToken?.userid);
                await localStorage.setItem("a_t", verify?.verifyAccessToken?.accessToken);
                await authorizeUser(verify?.verifyAccessToken?.accessToken, verify?.verifyAccessToken?.userid);
            }
        } else {
            handleTokenExpiration();
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error,Fail to verify token",
                key: "failVerify",
            });
        }
    };

    const getClientDeviceInfo = async () => {
        let browser = navigator.userAgentData;

        if (browser) {
            browser = browser.brands.find((b) => b.brand !== "Chromium" && !b.brand.includes("Brand"))?.brand;
        } else {
            browser = navigator.userAgent;
            if (browser.match(/chrome|chromium|crios/i)) {
                browser = "Chrome";
            } else if (browser.match(/firefox|fxios/i)) {
                browser = "Firefox";
            } else if (browser.match(/safari/i)) {
                browser = "Safari";
            } else if (browser.match(/opr\//i)) {
                browser = "Opera";
            } else if (browser.match(/edg/i)) {
                browser = "Edge";
            } else {
                browser = "Unknown";
            }
        }

        const userAgent = navigator.userAgent;
        let device = "Unknown";
        if (/Android/i.test(userAgent)) {
            device = "Android";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            device = "iOS";
        } else if (/Windows Phone|IEMobile|WPDesktop/i.test(userAgent)) {
            device = "Windows Phone";
        } else if (/Windows/i.test(userAgent)) {
            device = "Windows";
        } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
            device = "Mac";
        } else if (/Linux|X11/i.test(userAgent)) {
            device = "Linux";
        }

        try {
            const token = await fetchFCMToken();
            // const res = await axios.get("https://geolocation-db.com/json/");
            const res = await axios.get("https://api.ipify.org?format=json", {timeout: 2000});
            return {
                // ip: res?.data?.IPv4 || null,
                ip: res?.data?.ip || null,
                deviceToken: token || null,
                browser,
                device,
            };
        } catch (error) {
            return {
                ip: null,
                deviceToken: null,
                browser,
                device,
            };
        }
    };

    const verifyCryptoAddressFunction = async (email, transactionNo) => {
        try {
            const response = await verifyCryptoAddress(email, transactionNo);
            if (response?.verifyCryptoAddress?.__typename === "BaseError") {
                await baseErrorChecking(response?.verifyCryptoAddress?.errKey);
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("successMsg.verifyCryptoAddress"),
                    key: "verifyCryptoAddress",
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const checkTabActivityOnOpening = async () => {
        if (localStorage.getItem("_pat") && Date.now() - localStorage.getItem("_pat") >= 300000 /* 5s */) handleTokenExpiration()
    }

    return {
        authorizeUser,
        checkTokenExpiration,
        baseErrorChecking,
        handleTokenExpiration,
        trackUserActivity,
        startTokenExpirationCheck,
        userGhostLogin,
        getClientDeviceInfo,
        verifyCryptoAddressFunction,
        checkTabActivityOnOpening
    };
};

export {useVerification};
