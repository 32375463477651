import "./css/style.css"

export default function ExcotradesLoader() {

    return (
        <div id={"excotradesLoader"}>
            <div className={"loader"}>
                <div className={"temp_loader"}/>
            </div>
        </div>

    )
}